.select-custom-style1 {
    .MuiOutlinedInput-input {
            border-radius: 6px !important;
            border: 1px solid #000;
            height: 2em
    }
}

.select-custom-style2 {
    .MuiOutlinedInput-input {
            border-radius: 6px !important;
            border: 1px solid #000
    }
}