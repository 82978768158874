// color variants
@import "themes-vars.module.scss";
@import "../../pages/file/FileUploadModel.scss";
// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
.admin-card:hover {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.no-record {
  display: flex;
  flex-direction: column;
}
.page-not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tableCellBottom {
  border-bottom: 0;
}

.addProjectInput {
  border: 1px solid gray;
  border-radius: 10px;
}
.viewTitle {
  font-weight: 500 !important;
  font-size: 17px;
  margin-top: 0;
  padding-top: 0;
}
.MuiAppBar-root {
  border-bottom: none !important;
}
.line-with-text {
  position: relative;
  font-size: 18px;
}

.line-with-text::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 7vw;
  width: 36.5vw;
  border-bottom: 1px solid #dcd9d9;
}
.Toastify__close-button {
  right: 10px !important;
  top: 22px;
  position: absolute;
  // transform: translateX(50%) !important;
}
.highcharts-background {
  fill: none !important;
}

//loader for police report view details in reports page

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}
// @media (-webkit-device-pixel-ratio: 1.25) {
//   .reportsPage,.filepage ,.projectsPage,.askquestionContainer,.dashboardCard,
//     .dashboardChart,
//     .sideBar,
//     .logoutPopup{
//     zoom: 0.9;
//     -ms-zoom: 0.9;
//     -webkit-zoom: 0.9;
//     -moz-transform: scale(0.9);
//     -moz-transform-origin: left top;
//   }
// }

// @media (-webkit-device-pixel-ratio: 1.5) {
//   .reportsPage,
//     .filepage,.projectsPage,.askquestionContainer,.dashboardCard,.dashboardChart,.sideBar,.logoutPopup,.pagination,.modelPopup,.fileUpload,.dropDown{
//     zoom: 0.8;
//     -ms-zoom: 0.8;
//     -webkit-zoom: 0.8;
//     -moz-transform: scale(0.8);
//     -moz-transform-origin: left bottom;
//   }
// }

// @media (-webkit-device-pixel-ratio: 1.75) {
//   .reportsPage,
//     .filepage,.projectsPage,.askquestionContainer{
//     zoom: 0.8;
//     -ms-zoom: 0.8;
//     -webkit-zoom: 0.8;
//     -moz-transform: scale(0.8);
//     -moz-transform-origin: left top;
//   }
// }

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}
