body {
  margin: 0;
  font-family: 'Poppins',Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F2F7 !important;
  max-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (-webkit-device-pixel-ratio: 1.25) {
  html {
    zoom: 0.8;
    -ms-zoom: 0.8;
    -webkit-zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: left top;
  }
}
@media (-webkit-device-pixel-ratio: 1.5) {
  html {
    zoom: 0.7;
    -ms-zoom: 0.7;
    -webkit-zoom: 0.7;
    -moz-transform: scale(0.7);
    -moz-transform-origin: left top;
  }
}

/* @media (-webkit-device-pixel-ratio: 1.75) {
  html {
    zoom: 0.6;
    -ms-zoom: 0.6;
    -webkit-zoom: 0.6;
    -moz-transform: scale(0.6);
    -moz-transform-origin: left bottom;
  }
} */

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb {
  /* background: red; */
  border-radius: 10px;
}