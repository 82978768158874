.loader-pulse {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: blue;
    animation: load-pulse 0.85s infinite linear;
    margin-left: 30px;
}

@keyframes load-pulse {
    0% {
        transform: scale(0.15);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}