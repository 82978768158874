.chatContent {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
  max-height: 65vh;
  overflow-y: scroll;

  @media print {
    height: auto !important;
    max-height: none !important;
    // padding-left: 20px !important
  }
}

.date-Picker-custom {
  .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border-width: 0;
  }

  .MuiOutlinedInput-root {
    border-radius: 6px !important;
    border: black 1px solid;
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      box-shadow: 0 0 5px 0.1px #0400ffc4; /* Customize the shadow */
    }
  }

  .MuiOutlinedInput-input {
    height: 1.5em;
    font-size: 16px;
    background-color: #FFF;
    border-radius: 10px;

  }

  .MuiInputBase-input {
    border-radius: 12px !important;
    background: #fff;
    height: 1.5em;
  }
}


.select-custom-style {
  .MuiOutlinedInput-input {
    border-radius: 15px !important;
  }
}

.select-custom-lineOB {
  .MuiOutlinedInput-input {
    border-radius: 15px !important;
  }
}

/* HTML: <div class="loader"></div> */
.loader-upload {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #ffa516);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
