.flex-list {
  display: flex;
  justify-content: space-between;
  .MuiTypography-body1 {
    font-size: 14px;
    word-break: break-all;
    line-height: 2rem;
    width:50%;
  }
}

.custom-hr {
  height: 2px;
  border-width: 3;
  color: gray;
  background-color: gray;
  opacity: 0.15;
}

  .v2 {
    border-left: 2px solid grey;
    height: 34.6rem;
    position: absolute;
    left: 33.5%;
    margin-left: 3px;
    top: 40px;
    opacity: 0.1;
  }
.v3 {
    border-left: 2px solid grey;
    height: 34.6rem;
    position: absolute;
    left: 67%;
    margin-left: 3px;
    top: 40px;
    opacity: 0.1;
  }

  .Box-custom{
    background: white;
    padding: 28px 11px;
    margin-top: 15px;
    border-radius: 15px;
  }

  /* HTML: <div class="loader"></div> */
.loader-upload-claim {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#ffa516);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}

.select-custom-style3 {
  .MuiOutlinedInput-input {
          border-radius: 15px !important;
  }
}

.select-custom-style4 {
  .MuiOutlinedInput-input {
          border-radius: 15px !important;
  }
}