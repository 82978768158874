/* src/components/Chat.module.css */
.chatContainer {
  border: 1px solid black;
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 10px;
  padding: 10px;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}
.select-custom-style-chat {
  .MuiOutlinedInput-input {
          border-radius: 16px !important;
          height: 2em
  }
}
.icon {
  margin-right: 10px;
}

.userMessage {
  justify-content: flex-end;
  background-color: #e0e0e0; /* Light grey background for user messages */
  border-radius: 15px 15px 0 15px; /* Rounded corners for user messages */
}

.aiMessage {
  justify-content: flex-start;
  background-color: white; /* White background for AI messages */
  border-radius: 15px 15px 15px 0; /* Rounded corners for AI messages */
}

/* HTML: <div class="loader"></div> */
.loader1 {
  width: 120px;
  height: 20px;
  -webkit-mask: radial-gradient(circle closest-side,#000 94%,#0000) left/20% 100%;
  background: linear-gradient(#5553cf 0 0) left/0% 100% no-repeat #ddd;
  animation: l17 2s infinite steps(6);
}
@keyframes l17 {
    100% {background-size:120% 100%}
}
