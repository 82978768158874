
 .chatContent{
    display: flex;
            flex-direction: column;
            margin-left: 40px;
            margin-bottom: 10px;
            margin-top: 20px;
            max-height: 70vh;
            overflow-y: scroll;
            @media print {
                    height: auto !important;
                    max-height:none !important;
                    // padding-left: 20px !important
                }
 }

 .select-custom .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        border-radius: 100px !important;
 }