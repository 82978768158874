.choosefile_button {
  visibility: hidden;
}

.choosefile_lable {
  background-color: grey;
  color: white;
  padding: 0.7rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  cursor: pointer;
}


.tables-search {

  .MuiOutlinedInput-notchedOutline {
    border-radius: 15px;
  }

  .MuiInputBase-formControl {
    border-radius: 25px !important; // Set your desired border-radius value here
  }
}

.tables-filter {
  .MuiOutlinedInput-notchedOutline {
    border-radius: 15px !important;
  }
}